/**Created by liaoyingchao on 11/24/22.*/

<template>
  <div class="discount page-css list-css" v-loading="pageLoading">
    <div class="top-tools">
      <el-form ref="RefFilterForm" :model="form" label-width="90px">
        <div class="filter-items">
          <el-form-item class="filter-item" label="商品名称">
            <el-input v-model="form.productName" clearable placeholder="请输入商品名称"></el-input>
          </el-form-item>
          <el-form-item class="filter-item" label="商品分类">
            <el-select v-model="form.categoryId" placeholder="请选择商品分类" style="width: 100%;" clearable>
              <el-option
                      v-for="item in categoryOptions"
                      :key="item.categoryId"
                      :label="item.categoryName"
                      :value="item.categoryId"
              />
            </el-select>
          </el-form-item>
          <div class="btns-div">
            <el-button type="primary" :icon="Search" @click="filterEvent">查询</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <div class="full-container">
      <TableContainer ref="TabelContainer" :listApiFunction="getList">
        <template #default="tbData">
          <el-table
                  :height="tbData.data.tableHeight"
                  :data="tbData.data.tableData"
                  border
                  stripe
                  header-row-class-name="theader"
                  style="width: 100%">
            <el-table-column
                    prop="productName"
                    label="商品名称"
                    min-width="180">
            </el-table-column>
            <el-table-column
                    prop="productPicture"
                    label="商品图片"
                    min-width="100">
              <template #default="scope">
                <div v-if="scope.row.productPicture">
                  <img style="display: block;width: 50px;height: 50px;" :src="scope.row.productPicture">
                </div>
              </template>
            </el-table-column>
            <el-table-column
                    prop="psPrice"
                    label="原价(元)"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="psDiscount"
                    label="折扣"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="psSale"
                    label="销售价(元)"
                    min-width="100">
            </el-table-column>
            <el-table-column
                    prop="productUnit"
                    label="单位"
                    min-width="80">
            </el-table-column>
            <el-table-column
                    prop="psStock"
                    label="库存"
                    width="80">
            </el-table-column>
            <!--<el-table-column-->
            <!--prop="product"-->
            <!--label="商品分类"-->
            <!--min-width="100">-->
            <!--</el-table-column>-->
            <el-table-column
                    prop="state"
                    label="状态"
                    min-width="100">
              <template #default="scope">
                {{ scope.row.deleteFlag == 1 ? '已删除' : '正常' }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="createdTime"
                    label="创建时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.createdTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    prop="updateTime"
                    label="修改时间"
                    min-width="180">
              <template #default="scope">
                {{ timeYYYY_MM_DD_HH_mm_ss(scope.row.updateTime) }}
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="160">
              <template #default="scope">
                <el-button @click="editEvent(scope.row)" type="primary" text>编辑折扣</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </TableContainer>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            :title="editTitle"
            width="60%"
            destroy-on-close
    >
      <div class="dialog-content">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="120px">
          <el-form-item label="商品名称" prop="name">
            <el-input v-model="editForm.name" placeholder="请输入商品名称" maxlength="30" disabled/>
          </el-form-item>
          <el-form-item label="商品原价(元)">
            <el-input v-model="editForm.price" placeholder="请输入商品原价" disabled/>
          </el-form-item>
          <el-form-item label="商品折扣" prop="discount">
            <el-input v-model="editForm.discount" placeholder="请输入商品折扣，只能输入0-10之间的数，0或者空或者10为不打折" @change="discountChanged"/>
          </el-form-item>
          <el-form-item label="商品销售价(元)">
            <el-input v-model="editForm.sale" placeholder="请输入商品销售价格" disabled/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">保存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {ref, reactive} from 'vue'
  import {
    Search,
    Plus,
  } from '@element-plus/icons-vue'
  import {ElMessage, ElMessageBox} from 'element-plus'
  import TableContainer from '@/components/table-container/index'
  import FormCheck from "@/utils/formCheck";
  import categoryApi from '@/api/category'
  import productApi from '@/api/product'
  import {timeYYYY_MM_DD_HH_mm_ss} from '@/utils/time-format.js'

  const pageLoading = ref(false)
  const RefFilterForm = ref(null)

  const form = reactive({
    productName: '',
    categoryId: ''
  })

  function getList(data) {
    return productApi.skuList(data);
  }

  const categoryOptions = ref([])

  function getCategoryList() {
    categoryApi.list({
      pageNumber: 1,
      pageSize: 100
    }).then(res => {
      if (res.code == 0) {
        categoryOptions.value = res.data || [];
      } else {
        ElMessage.error(res.msg);
      }
    })
  }

  getCategoryList();

  const TabelContainer = ref(null)

  function filterEvent() {
    TabelContainer.value.filterEvent(form)
  }

  let dialogVisible = ref(false)
  const editTitle = ref('')

  const editForm = reactive({
    id: '',
    name: '',
    price: 0,
    discount: '',
    sale: 0,
  })

  const formRules = {
    discount: [FormCheck.justPrice('请输入正确的折扣'), FormCheck.valueIn(0, 10)],
  }

  function discountChanged() {
    let discount = editForm.discount
    if (discount > 0 && discount < 10) {
      editForm.sale = Math.ceil(parseFloat(editForm.price) * parseFloat(discount) * 10) / 100.0
    } else {
      editForm.sale = editForm.price
    }
  }

  const Ref_editForm = ref(null)

  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        pageLoading.value = true
        productApi.updateDiscount({
          psId: editForm.id,
          psDiscount: editForm.discount + ''
        }).then(res => {
          if (res.code == 0) {
            dialogVisible.value = false
            // filterEvent();
            TabelContainer.value.loadData()
          } else {
            ElMessage.error(res.msg);
          }
        }).finally(() => {
          pageLoading.value = false
        })
      } else {
        console.log('error submit!', fields)
      }
    })
  }

  function editEvent(item) {
    dialogVisible.value = true
    editTitle.value = '编辑商品库存'

    editForm.id = item.psId
    editForm.name = item.productName
    editForm.price = item.psPrice || 0
    editForm.discount = item.psDiscount || ''
    editForm.sale = item.psSale || 0
  }

</script>

<style lang="stylus" scoped type="text/stylus">
  .discount {
    >>> .dialog-content {
      max-height 65vh;
      overflow-y auto;
      padding-right 40px;
    }
  }
</style>